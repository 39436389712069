import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=f04ead3c"
import script from "./Events.vue?vue&type=script&lang=js"
export * from "./Events.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventHeader: require('/vercel/path0/components/components/events/EventHeader.vue').default,EventBlock: require('/vercel/path0/components/components/events/EventBlock.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
