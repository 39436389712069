
import { getEventsPreview } from '~/graphql/fragments-collection.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      events: [],
    }
  },

  computed: {
    chunksOfTwo() {
      const len = 2
      const arr = this.data.event_blocks
      if (!Array.isArray(arr) || !len) {
        return []
      }
      const chunks = []
      let i = 0
      const n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, (i += len)))
      }
      return chunks
    },

    isNeededToFetchEvents() {
      const categories = []
      let shouldFetchEvents = false
      this.data.event_blocks.forEach((block) => {
        if (
          block.load_events_automatically.value === 'by_hand' &&
          block.events
        ) {
          return
        }

        shouldFetchEvents = true
        // add categories to filter
        block.event_categories.forEach((category) => {
          if (!categories.includes(category)) {
            categories.push(category)
          }
        })
      })

      return { categories, shouldFetchEvents }
    },

    graphQlFilter() {
      const filter = {
        site: this.$i18n.locale,
      }

      // FILTER BY CATEGORIES
      const categories = this.isNeededToFetchEvents.categories
      if (Array.isArray(categories)) {
        if (categories.length === 1) {
          filter.event_categories = {
            contains: categories[0].slug,
          }
        }
        if (categories.length > 1) {
          const filterCategories = categories.map((category) => category.slug)
          filter.event_categories = { in: filterCategories }
        }
      }
      return filter
    },
  },

  watch: {
    data: {
      handler(newData) {
        if (this.isNeededToFetchEvents.shouldFetchEvents) {
          this.getEvents()
        }
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getEvents() {
      try {
        const res = await this.$graphql.cms.request(getEventsPreview, {
          limit: 0,
          page: null,
          filter: this.graphQlFilter,
          sort: 'from asc',
        })
        this.events = res.entries?.data
      } catch (e) {
        console.log('Events/getEvents', e)
      }
    },
  },
}
