
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
    },

    wide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    eventDate() {
      const from = this.event.from
      const to = this.event.to
      if (!from) return ''
      if (!to) return this.formatDate(from, 'dd.MM.')
      return `${this.formatDate(from, 'dd.MM.')} – ${this.formatDate(
        to,
        'dd.MM.'
      )}`
    },

    // -> .replace(/-/g, '/') to satisfy Safari. Safari doesn't support "2020-08-26 12:29:33" date format
    // https://www.linkedin.com/pulse/fix-invalid-date-safari-ie-hatem-ahmad/
    eventYear() {
      let from = this.event.from
      let to = this.event.to
      if (!from) return ''
      if (!to) return this.formatDate(from, 'yyyy')

      from = new Date(from.replace(/-/g, '/'))
      to = new Date(to.replace(/-/g, '/'))
      if (this.$dateFns.isSameYear(from, to)) {
        return this.formatDate(from, 'YYY')
      } else {
        return `${this.formatDate(from, 'YYY')}/${this.formatDate(to, 'YYY')}`
      }
    },
  },

  methods: {
    formatDate(date, format) {
      if (!date || !format) return ''

      // check if it is date object
      if (date instanceof Date) {
        return this.$dateFns.format(date, format)
      }
      return this.$dateFns.format(new Date(date.replace(/-/g, '/')), format)
    },
  },
}
