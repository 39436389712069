
export default {
  props: {
    block: {
      type: Object,
      default: () => {},
    },

    events: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredEvents() {
      const events = this.events.filter((event) =>
        this.block.event_categories.some(
          (category) => event.event_categories.slug === category.slug
        )
      )
      return events.splice(0, parseInt(this.block.amount_events || 6))
    },
  },
}
